import React, { FC } from 'react';
import { Text, Avatar, AvatarSize, Button } from 'wix-ui-tpa/cssVars';

import { MemberListItem } from '../../../../../types';
import { classes } from './ActionableMemberRow.st.css';

interface MemberData {
  member: MemberListItem;
  buttonText: string;
  onAction?: () => void;
}

export const ActionableMemberRow: FC<MemberData> = ({
  member,
  buttonText,
  onAction,
}) => {
  return (
    <div className={classes.gridContainer}>
      <div className={classes.avatar}>
        <Avatar
          size={AvatarSize.medium}
          name={member.name}
          src={member.photo}
        />
      </div>
      <div className={classes.description}>
        <Text>{member.name}</Text>
      </div>
      <div className={classes.action}>
        <Button upgrade={true} onClick={onAction}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
