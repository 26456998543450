import {TicketWithFormattedSalePeriod} from '@wix/events-types'
import {AnyAction} from 'redux'

const defaultState: TicketWithFormattedSalePeriod[] = []

export const demoTickets = (state = defaultState, action: AnyAction): TicketWithFormattedSalePeriod[] => {
  switch (action.type) {
    default:
      return state
  }
}
