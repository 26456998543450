import { MemberRole, ProfilePrivacyStatus } from '../../../../../types';

const isPublicProfile = (privacyStatus: ProfilePrivacyStatus) => {
  return privacyStatus === ProfilePrivacyStatus.PUBLIC;
};

const isOwner = (role: MemberRole | undefined) => role === MemberRole.OWNER;

export const shouldShowCommunityUrlItem = (
  isSocial: boolean,
  privacyStatus: ProfilePrivacyStatus,
) => isSocial && isPublicProfile(privacyStatus);

export const shouldShowProfileVisibilityItem = (role: MemberRole | undefined) =>
  !isOwner(role);

export const shouldShowBlockedMembersItem = (role: MemberRole | undefined) =>
  !isOwner(role);
