import {AppManifestBuilder} from '@wix/yoshi-flow-editor'
import {EVENTS_WIDGET_ID} from '@wix/wix-events-commons-statics'
import type {EditorSDK} from '@wix/platform-editor-sdk'
import {SdkWrapper} from '../services/sdk'
import {ARTICLES} from '../../components/widget/Settings/constants'
import {TAB} from '../../commons/constants/navigation'
import {EVENTS_APP_DEF_ID} from '../constants'
import {TFunction} from '../services/translations'

export const widgetGfppBuilder =
  ({t, editorSdk, token}: {t: TFunction; editorSdk: EditorSDK; token: string}) =>
  async (appManifestBuilder: AppManifestBuilder) => {
    const sdkWrapper = SdkWrapper(editorSdk, token)

    const widgetSettingsUrl = await sdkWrapper.getSettingsUrl(EVENTS_WIDGET_ID, EVENTS_APP_DEF_ID)

    return appManifestBuilder.configureWidget(EVENTS_WIDGET_ID, widgetBuilder =>
      widgetBuilder
        .gfpp()
        .set('mainAction2', {
          label: t('gfppWidgetSettingsButtonDisplayEvents'),
          onClick: event => {
            sdkWrapper.openSettings(
              {
                title: t('widgetSettingsTitle'),
                url: widgetSettingsUrl,
                componentRef: event.detail.componentRef,
                initialData: {
                  tab: 'events',
                },
                helpId: ARTICLES[TAB.MY_EVENTS],
              },
              {preloader: true},
            )
          },
        })
        .set('settings', {
          onClick: event =>
            sdkWrapper.openSettings(
              {
                title: t('widgetSettingsTitle'),
                url: widgetSettingsUrl,
                componentRef: event.detail.componentRef,
                helpId: ARTICLES[TAB.MAIN],
              },
              {preloader: true},
            ),
        }),
    )
  }
