import type {EditorSDK, PagesPanelTabType, PlatformEvent} from '@wix/platform-editor-sdk'
import {EVENT_DETAILS_SECTION_ID} from '@wix/wix-events-commons-statics'
import {getSectionPageRef} from './pages-panel'
import {createWithApproval} from './services/concurrent-editing'
import {FlowApi} from './types'
import {SdkWrapper} from './services/sdk'

export const EVENT_APP_ACTION_CLICKED = 'appActionClicked'
export const EVENT_MANAGE_EVENTS = 'manageEvents'
export const EVENT_DELETE_EVENTS = 'deleteEvents'

export const APP_ACTION_MANAGE_EVENTS = 'manageEvents'
export const APP_ACTION_CREATE_EVENT = 'createEvent'
export const APP_ACTION_OPEN_EVENT_PAGES = 'openEventPages'
export const APP_ACTION_ADD_EVENT_ELEMENTS = 'addEventElements'

interface HandlerParams<PayloadType = any> {
  editorSdk: EditorSDK
  token: string
  payload?: PayloadType
  flowApi: FlowApi
}

interface AppActionPayload {
  actionId: string
}

const appActionHandlers: {[key: string]: (params: HandlerParams<AppActionPayload>) => void} = {
  [APP_ACTION_MANAGE_EVENTS]: async ({editorSdk, token}) => {
    await editorSdk.editor.openDashboardPanel(token, {closeOtherPanels: false, url: '/events'})
    return SdkWrapper(editorSdk, token).onDashboardClose()
  },
  [APP_ACTION_CREATE_EVENT]: async ({editorSdk, token}) => {
    await editorSdk.editor.openDashboardPanel(token, {closeOtherPanels: false, url: '/events/new'})
    return SdkWrapper(editorSdk, token).onDashboardClose()
  },
  [APP_ACTION_OPEN_EVENT_PAGES]: async ({editorSdk, token}) => {
    const pageRef = (await getSectionPageRef(EVENT_DETAILS_SECTION_ID, editorSdk, token)) as any
    await editorSdk.editor.openPagesPanel({token}, {pageRef, initialSettingsTabType: 'PAGE_INFO' as PagesPanelTabType})
  },
  [APP_ACTION_ADD_EVENT_ELEMENTS]: async ({editorSdk, token}) => {
    await editorSdk.editor.deeplink.show(token, {type: 'addPanel', params: [token]})
  },
}

const handlers: {[key: string]: (params: HandlerParams) => void} = {
  [EVENT_MANAGE_EVENTS]: async ({editorSdk, token}) => {
    await editorSdk.editor.openDashboardPanel(token, {url: 'events', closeOtherPanels: false})
    return SdkWrapper(editorSdk, token).onDashboardClose()
  },
  [EVENT_DELETE_EVENTS]: async ({editorSdk, token, flowApi}) => {
    await createWithApproval(
      editorSdk,
      token,
      false,
      flowApi,
    )(async () => {
      // Deleting main page first causes confirmation popup to show up
      const eventsPages = await editorSdk.document.pages.getApplicationPages(token)
      const essentialPage = eventsPages.find(page => page.tpaPageId === EVENT_DETAILS_SECTION_ID)

      await editorSdk.document.pages.remove(token, {
        // @ts-expect-error
        pageRef: {id: essentialPage.id},
      })

      // In theory app should already be deleted at this point but it is often not the case
      await editorSdk.document.tpa.app.delete(token)
    })
  },
  [EVENT_APP_ACTION_CLICKED]: async (params: HandlerParams<AppActionPayload>) => {
    const {actionId} = params.payload

    await appActionHandlers?.[actionId]?.(params)
  },
}

export const onEventFactory =
  (getToken: () => string, getFlowApi: () => FlowApi) =>
  async ({eventType, eventPayload}: PlatformEvent, editorSdk: EditorSDK) => {
    await handlers?.[eventType]?.({
      editorSdk,
      payload: eventPayload,
      token: getToken(),
      flowApi: getFlowApi(),
    })
  }
