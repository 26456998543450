import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Comments',
  props: {
    myProp: {
      type: WidgetPropertyType.STRING,
    },
  },
  methods: {},
  events: {
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
