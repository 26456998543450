import { BookingsBOLink } from '@wix/bookings-catalog-calendar-viewer-utils';

const deprecatedBookingsAddNewServicePanel =
  'bookings/scheduler/owner/offerings/wizard';
const bookingsAddNewServicePanel = `bookings/${BookingsBOLink.ADD_NEW_SERVICE}`;

const deprecatedBookingsDashboardPanel = 'bookings/scheduler/owner/offerings';
const bookingsServicesPanel = `bookings/${BookingsBOLink.SERVICES}`;

export async function openBookingsDashboardAddNewService(
  editorSdkAdapter,
  editorSDK,
  appToken,
  isUseNewBOLinksInUoUEnabled: boolean,
) {
  await editorSDK.editor.openDashboardPanel(appToken, {
    url: isUseNewBOLinksInUoUEnabled
      ? bookingsAddNewServicePanel
      : deprecatedBookingsAddNewServicePanel,
    closeOtherPanels: false,
  });
  editorSdkAdapter.refreshApp('MANAGE_BOOKINGS_CLOSE', true);
}
export async function openManageBookings(
  editorSdkAdapter,
  editorSDK,
  appToken,
  isUseNewBOLinksInUoUEnabled: boolean,
) {
  await editorSDK.editor.openDashboardPanel(appToken, {
    url: isUseNewBOLinksInUoUEnabled
      ? bookingsServicesPanel
      : deprecatedBookingsDashboardPanel,
    closeOtherPanels: false,
  });
  editorSdkAdapter.refreshApp('MANAGE_BOOKINGS_CLOSE', true);
}
