import { ControllerFlowAPI, VisitorLogger } from '@wix/yoshi-flow-editor';
import {
  bookingsCalendarClick,
  bookingsCalendarTimePickerLoad,
  bookingsCalendarDatePickerLoad,
  bookingsCalendarErrorMessages,
  bookingsCalendarBookingDetailsLoad,
  bookingsContactInfoSaveSuccess,
  bookingsPaymentMethodSelectionNextClicked,
  bookingsLoginUserAccountAppointmentsRescheduleSuccess,
  bookingsCalendarPageLoaded,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { CalendarState } from '../../components/BookingCalendar/controller';
import {
  WixOOISDKAdapter,
  BookingsQueryParams,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { BusinessInfo, Service } from '@wix/bookings-uou-types';
import { Preset } from '../../types/types';
import { CalendarContext } from '../context/contextFactory';
import { getUrlQueryParamValue } from '@wix/bookings-catalog-calendar-viewer-utils';

export interface CalendarBiLogger extends VisitorLogger {
  update: (state: CalendarState, context: CalendarContext) => void;
  bookingsCalendarClick: typeof bookingsCalendarClick;
  bookingsCalendarPageLoaded: typeof bookingsCalendarPageLoaded;
  bookingsCalendarTimePickerLoad: typeof bookingsCalendarTimePickerLoad;
  bookingsCalendarDatePickerLoad: typeof bookingsCalendarDatePickerLoad;
  bookingsCalendarErrorMessages: typeof bookingsCalendarErrorMessages;
  bookingsCalendarBookingDetailsLoad: typeof bookingsCalendarBookingDetailsLoad;
  bookingsContactInfoSaveSuccess: typeof bookingsContactInfoSaveSuccess;
  bookingsPaymentMethodSelectionNextClicked: typeof bookingsPaymentMethodSelectionNextClicked;
  bookingsLoginUserAccountAppointmentsRescheduleSuccess: typeof bookingsLoginUserAccountAppointmentsRescheduleSuccess;
}

type CalendarContextWithoutBiLogger = Omit<CalendarContext, 'biLogger'>;

export function createCalendarBiLogger(
  flowAPI: ControllerFlowAPI,
  initialState: CalendarState,
  wixSdkAdapter: WixOOISDKAdapter,
  settings: any,
  settingsParams: any,
  preset: Preset,
  context: CalendarContextWithoutBiLogger,
  businessInfo?: BusinessInfo,
): CalendarBiLogger {
  const { bi: viewerBiLogger } = flowAPI;

  const getServiceLocationIds = (service: Service) => {
    return service?.locations
      ?.map((location) => location?.businessLocation?.id)
      .filter((locationId) => locationId !== undefined);
  };

  const getServiceStaffIds = (service: Service) => {
    return service?.staffMembers?.map((staffMember) => staffMember.id);
  };

  const getServiceProperties = (state: CalendarState) => {
    const serviceProperties = state.servicesInView.reduce(
      (previousServiceProperties, service) => {
        return {
          ...previousServiceProperties,
          [service.id]: {
            serviceId: service?.id,
            serviceType: service?.info.type,
            locationIds: getServiceLocationIds(service),
            staffMemberIds: getServiceStaffIds(service),
            paymentOptions: service?.payment?.offeredAs,
            connectedSolutions: [],
          },
        };
      },
      {},
    );
    return JSON.stringify(serviceProperties);
  };

  const getSelectedFilters = (state: CalendarState) => {
    const service = state.servicesInView?.[0];
    const PRICE_OPTION = state.serviceVariantsMap[service?.id]?.options;
    return JSON.stringify({ ...state.filterOptions, PRICE_OPTION });
  };

  const getPriceType = (state: CalendarState) => {
    const service = state.servicesInView?.[0];
    return JSON.stringify(
      state.serviceVariantsMap[service?.id]?.options?.values?.map(
        ({ type }) => type,
      ) || [],
    );
  };

  const mapStateToDefaultBiParams = (state: CalendarState) => {
    const service = state?.servicesInView[0];
    return {
      serviceType: service?.info.type,
      serviceId: service?.id,
      selectedTimezone: state?.selectedTimezone,
      errorMessage: JSON.stringify(state?.calendarErrors),
      serviceProperties: getServiceProperties(state),
      selectedFilters: getSelectedFilters(state),
      selectedDate: state?.selectedDate,
      selectedRange: JSON.stringify(state?.selectedRange),
      bookingId: state?.rescheduleBookingDetails?.id,
      priceType: getPriceType(state),
    };
  };

  const mapContextToDefaultBiParams = (ctx: CalendarContextWithoutBiLogger) => {
    const { calendarSelections } = ctx;

    if (!calendarSelections) {
      return {};
    }

    return {
      numberOfServices: calendarSelections.services.length,
      extraInfo: JSON.stringify(
        calendarSelections.services.map(
          ({ id: serviceId, resources: staffMemberIds }) => ({
            serviceId,
            staffMemberIds,
          }),
        ),
      ),
    };
  };

  const updateDefaultBiParams = (
    state: CalendarState,
    ctx: CalendarContext,
  ) => {
    viewerBiLogger?.updateDefaults({
      ...mapStateToDefaultBiParams(state),
      ...mapContextToDefaultBiParams(ctx),
    });
  };

  viewerBiLogger?.updateDefaults({
    pageName: preset,
    referralInfo: getUrlQueryParamValue(
      flowAPI.controllerConfig.wixCodeApi,
      BookingsQueryParams.REFERRAL,
    ),
    businessProperties: JSON.stringify({
      language: businessInfo?.language,
      countryCode: businessInfo?.countryCode,
    }),
    ...mapStateToDefaultBiParams(initialState),
    ...mapContextToDefaultBiParams(context),
    defaultDateAtFirstLoad: settings.get(settingsParams.initializeCalendarDate),
    layout: settings.get(settingsParams.calendarLayout),
  });

  return Object.assign(viewerBiLogger!, {
    update: updateDefaultBiParams,
  }) as CalendarBiLogger;
}
