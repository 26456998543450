import {maybeInstallMembersArea} from '@wix/members-area-integration-kit'
import type {EditorSDK, TPAComponentType} from '@wix/platform-editor-sdk'
import {GROUPS_APP_DEF_ID, PAID_PLANS_APP_DEF_ID, SCHEDULE_SECTION_ID} from '@wix/wix-events-commons-statics'
import {BASE_INSTALL_CONFIG, EVENTS_APP_DEF_ID, LIVE_VIDEO_APP_DEF_ID} from './constants'
import {ProgressBar} from './services/progress-bar'
import {WithApproval} from './services/concurrent-editing'
import {TFunction} from './services/translations'

export const handleAppInstalled = async ({
  appDefinitionId,
  disableMembersInstall,
  adi,
  progressBar,
  withApproval,
}: HandleAppInstalledParams) => {
  switch (appDefinitionId) {
    case EVENTS_APP_DEF_ID:
      try {
        progressBar.open(BASE_INSTALL_CONFIG)

        if (!adi && !disableMembersInstall) {
          await withApproval(() => maybeInstallMembersArea())
        }

        await progressBar.close()
      } catch (e) {
        console.error(e)
        await progressBar.close()
      }

      return
    default:
      return
  }
}

export const isEventsInstalled = ({editorSdk, token, forcedPageInstall}: IsEventsInstalledParams) => {
  if (forcedPageInstall) {
    // is platform app installed
    return editorSdk.application.isApplicationInstalled(token, {
      appDefinitionId: EVENTS_APP_DEF_ID,
    })
  } else {
    // is widget installed
    return editorSdk.tpa.isApplicationInstalled(token, {
      appDefinitionId: EVENTS_APP_DEF_ID,
    })
  }
}

const addPaidPlans = ({editorSdk, token}: AddParams) =>
  editorSdk.document.tpa.add.application(token, {appDefinitionId: PAID_PLANS_APP_DEF_ID})

const addLiveVideo = ({editorSdk, token}: AddParams) =>
  editorSdk.document.tpa.add.application(token, {appDefinitionId: LIVE_VIDEO_APP_DEF_ID})

const addGroups = ({editorSdk, token}: AddParams) =>
  editorSdk.document.tpa.add.application(token, {appDefinitionId: GROUPS_APP_DEF_ID})

export const handleMigrate = ({
  payload,
  withApproval,
  editorSdk,
  token,
  t,
  progressBar,
  forcedPageInstall,
}: HandleMigrateParams) => {
  if (payload?.addPaidPlans) {
    return withApproval(() => addPaidPlans({editorSdk, token}))
  }

  if (payload?.addSchedulePage) {
    return withApproval(() =>
      installPage({
        editorSdk,
        token,
        pageId: SCHEDULE_SECTION_ID,
        title: t('pagesPanelEventsScheduleTitle'),
        progressBar,
        forcedPageInstall,
      }),
    )
  }

  if (payload?.addLiveVideo) {
    return withApproval(() => addLiveVideo({editorSdk, token}))
  }

  if (payload?.addGroups) {
    return withApproval(() => addGroups({editorSdk, token}))
  }

  return
}

export const installPage = async ({
  editorSdk,
  token,
  pageId,
  title,
  progressBar,
  forcedPageInstall,
}: InstallPageParams) => {
  // This is needed because Editor script runs even if Events are uninstalled...
  // Not having this check makes it impossible to uninstall Events

  const eventsInstalled = await isEventsInstalled({editorSdk, token, forcedPageInstall})
  const pageInstalled = await editorSdk.tpa.isAppSectionInstalled(token, {
    sectionId: pageId,
    appDefinitionId: EVENTS_APP_DEF_ID,
  })

  if (eventsInstalled && !pageInstalled) {
    if (forcedPageInstall) {
      progressBar.open(BASE_INSTALL_CONFIG)
    }

    try {
      return await editorSdk.tpa.add.component(token, {
        appDefinitionId: EVENTS_APP_DEF_ID,
        componentType: 'PAGE' as TPAComponentType.Page,
        page: {
          pageId,
          title,
          isHidden: true,
          shouldNavigate: false,
        },
      })
    } catch (e) {
      console.log('Prevented exception from breaking editor script!', e)
      await progressBar.close()
      return null
    }
  }
}

interface GenericParams {
  editorSdk: EditorSDK
  token: string
  forcedPageInstall: boolean
}

interface HandleAppInstalledParams {
  appDefinitionId: string
  disableMembersInstall: boolean
  adi: boolean
  progressBar: ProgressBar
  withApproval: WithApproval
}

interface HandleMigrateParams extends GenericParams {
  payload: any
  withApproval: WithApproval
  t: TFunction
  progressBar: ProgressBar
}

interface IsEventsInstalledParams extends GenericParams {}

interface InstallPageParams extends GenericParams {
  pageId: string
  title?: string
  progressBar: ProgressBar
}

interface AddParams extends Omit<GenericParams, 'forcedPageInstall'> {}
