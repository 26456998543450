import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  const ui = {
    likesFilledNumber: 'likesFilledNumber',
    likesDefaultNumber: 'likesDefaultNumber',
    views: 'viewsNumber',
    comments: 'commentsNumber',
  };

  widgetBuilder.configureConnectedComponents(ui.likesFilledNumber, (builder) =>
    builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' }),
  );

  widgetBuilder.configureConnectedComponents(ui.likesDefaultNumber, (builder) =>
    builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' }),
  );

  widgetBuilder.configureConnectedComponents(ui.views, (builder) =>
    builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' }),
  );

  widgetBuilder.configureConnectedComponents(ui.comments, (builder) =>
    builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' }),
  );
};
