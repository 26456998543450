import {IStylesGetParam} from '@wix/tpa-settings'
import stylesParams from './stylesParams'

export const StyleSelectors = (get: IStylesGetParam) => {
  const isShortDateLocationVisible = () => get(stylesParams.shortDateLocationVisible)

  const isDescriptionVisible = () => get(stylesParams.descriptionVisible)

  const isMembersVisible = () => get(stylesParams.membersVisible)

  const isMembershipsVisible = () => get(stylesParams.membershipsVisible)

  const isGroupVisible = () => get(stylesParams.groupVisible)

  const isAboutSectionVisible = () => get(stylesParams.aboutSectionVisible)

  const isScheduleVisible = () => get(stylesParams.scheduleVisible)

  const isSocialShareVisible = () => get(stylesParams.socialShareVisible)

  const isHeaderRegistrationButtonVisible = () => get(stylesParams.headerRegistrationButtonVisible)

  const isMapVisible = () => get(stylesParams.mapVisible)

  const isReadMoreButtonVisible = () => get(stylesParams.readMoreButtonVisible)

  return {
    isShortDateLocationVisible,
    isDescriptionVisible,
    isMembersVisible,
    isMembershipsVisible,
    isGroupVisible,
    isAboutSectionVisible,
    isScheduleVisible,
    isSocialShareVisible,
    isHeaderRegistrationButtonVisible,
    isMapVisible,
    isReadMoreButtonVisible,
  }
}
