import React from 'react';
import classNames from 'classnames';
import {
  EXPERIMENTS,
  getLayoutName,
  isExperimentEnabled,
  resolveId,
  SECTION_HOMEPAGE,
} from '@wix/communities-blog-client-common';
// @ts-expect-error
import shouldShowPostUpdateDate from '@wix/communities-blog-universal/dist/src/utils/should-show-post-update-date';
import useDeviceType from '../../hooks/use-device-type';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import useFontClassName from '../../hooks/use-font-class-name';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import usePermissions from '../../hooks/use-permissions';
import { usePostBorderWidth } from '../../hooks/use-post-border-width';
import {
  getIsCategoryLabelsEnabled,
  getIsMoreButtonEnabled,
  getIsTagsEnabled,
} from '../../selectors/app-settings-selectors';
import { shouldApplyPostDesignInFeed as getShouldApplyPostDesignInFeed } from '../../selectors/full-post-selectors';
import { getLayoutType } from '../../selectors/layout-selectors';
import { getSection } from '../../selectors/section-selectors';
import { getPostActions } from '../../services/post-actions';
import {
  getIsDesktop,
  isSSR,
} from '../../store/basic-params/basic-params-selectors';
import { getIsMemberAreaInstalled } from '../../store/communities-context/communities-context-selectors';
import { getIsPostInPreview } from '../../store/is-post-in-preview/is-post-in-preview-selectors';
import {
  getPostAverageRating,
  getPostTotalRatings,
} from '../../store/post-ratings/post-ratings-selector';
import CategoryLabelList from '../category-label-list';
import { MoreButton } from '../more-button';
import { importPostActions } from '../post-actions';
import PostContent from '../post-content';
import PostHeaderIcons from '../post-header-icons';
import PostMainActions from '../post-main-actions';
import PostPageMetadata from '../post-page-metadata';
import PostTitle from '../post-title';
import RatingsDisplay from '../ratings-display';
import { RatingsDisplayLayout } from '../ratings-display/ratings-display';
import { useActions, useSelector } from '../runtime-context';
import { TagsWithTranslation } from '../tags';
import PostUpdatedDate from './post-updated-date';
import styles from './post.scss';

type OwnProps = {
  post: any;
  isInPostPage?: boolean;
  onRatingsDisplayClick?: () => void;
};

type Props = OwnProps;

export function Post(props: Props) {
  const { isMobile } = useDeviceType();
  const { post, isInPostPage } = props;
  const { postPageMoreButtonClicked } = useActions();
  const {
    commentsLink,
    isCategoryLabelsEnabled,
    isDesktop,
    isMoreButtonEnabled,
    isPostRatingLoading,
    isTagsEnabled,
    layoutName,
    postAverageRating,
    postLink,
    postTotalRatings,
    renodeRatings,
    showMoreButton,
    shouldApplyPostDesignInFeed,
  } = useSlice(props);
  const { titleFontClassName, contentFontClassName } = useFontClassName();
  const {
    isMetadataHeaderVisible = true,
    showPostRating,
    showPostTitle,
    showPostUpdatedDate,
  } = useFeedMetadataSettings({ post });
  const { borderWidth } = usePostBorderWidth();
  const { applyFeedDesign, getPostClassName } = useIsFeedDesignEnabled();
  const containerClassName = classNames(
    styles.container,
    contentFontClassName,
    'blog-card-background-color',
    'blog-card-border-color',
    'blog-text-color',
    getPostClassName('border-color', 'post-container', 'background-color'),
  );
  const moreButtonId = `more-button-${resolveId(post)}`;
  const titleContainerClassName = classNames(
    styles.title,
    !isMetadataHeaderVisible && styles.withoutHeaderMetadata,
    post.isPinned && styles.withIcons,
    !isInPostPage && styles.notPostPage,
    showMoreButton && isMoreButtonEnabled
      ? styles.withShowMoreButton
      : styles.withoutShowMoreButton,
  );
  const ratingsVisible = isInPostPage
    ? showPostRating
    : showPostRating && !!postTotalRatings;

  const titleClassName = classNames(
    applyFeedDesign
      ? getPostClassName('title-font', 'title-color')
      : [titleFontClassName],
    'blog-post-title-color',
  );

  const metadataClassName = classNames(
    applyFeedDesign &&
      getPostClassName('description-font', 'description-color'),
  );
  const postContentClassName = classNames(
    styles.content,
    !ratingsVisible &&
      showPostUpdatedDate &&
      shouldShowPostUpdateDate(post) &&
      styles.postUpdateWithoutRating,
    applyFeedDesign &&
      getPostClassName('description-style-font', 'description-color'),
  );

  const headerRightClass =
    isDesktop && (isInPostPage || shouldApplyPostDesignInFeed)
      ? classNames(styles.headerRight, styles.postPageDesktopHeader)
      : styles.headerRight;

  return (
    <article
      className={containerClassName}
      style={{ borderWidth }}
      data-hook="post"
    >
      <div className={styles.contentSpacer}>
        <div className={styles.contentWrapper}>
          <>
            <div className={styles.mobileContainer}>
              <div className={styles.header}>
                {isMetadataHeaderVisible && (
                  <PostPageMetadata
                    post={post}
                    className={metadataClassName}
                    linkClassName={classNames(
                      getPostClassName('link-hashtag-hover-color'),
                    )}
                  />
                )}
                <div className={headerRightClass}>
                  <PostHeaderIcons post={post} />
                  {isMoreButtonEnabled && (
                    <MoreButton
                      className={classNames(
                        styles.moreButton,
                        styles.hideInPrint,
                      )}
                      id={moreButtonId}
                    >
                      {async () => {
                        postPageMoreButtonClicked({
                          postId: resolveId(post),
                        });
                        const PostActions = await importPostActions();
                        return <PostActions post={post} />;
                      }}
                    </MoreButton>
                  )}
                </div>
              </div>
              {showPostTitle && (
                <div
                  tabIndex={-1}
                  className={titleContainerClassName}
                  data-hook="post-title"
                >
                  <PostTitle
                    fullRoute={post.link}
                    isInPostPage={isInPostPage}
                    className={titleClassName}
                    type={PostTitle.SINGLE}
                    title={post.title}
                    to={postLink}
                    linkClassName={classNames(
                      getPostClassName('link-hashtag-hover-color'),
                    )}
                  />
                </div>
              )}
            </div>
            {showPostUpdatedDate && <PostUpdatedDate post={post} />}
          </>
          {ratingsVisible && (
            <button
              className={styles.postRating}
              onClick={props.onRatingsDisplayClick}
            >
              <RatingsDisplay
                rating={postAverageRating}
                count={postTotalRatings}
                isLoading={isInPostPage ? isPostRatingLoading : false}
                useTransitions={renodeRatings || isPostRatingLoading}
                layout={
                  isMobile
                    ? RatingsDisplayLayout.bracket_separated
                    : RatingsDisplayLayout.default
                }
              />
            </button>
          )}
        </div>
      </div>
      <div className={postContentClassName} data-hook="post-description">
        <PostContent post={post} />
      </div>
      <div className={styles.contentSpacer}>
        <div className={styles.contentWrapper}>
          <div
            id="post-footer"
            className={classNames(styles.mobileContainer, styles.hideInPrint)}
          >
            {isTagsEnabled && (
              <TagsWithTranslation
                postId={post.id}
                isPostPageTags={true}
                tags={post.tags}
                shouldSortTags={false}
              />
            )}
            {isCategoryLabelsEnabled && !isDesktop && (
              <div className={styles.categoryList} data-hook="post__categories">
                <CategoryLabelList post={post} />
              </div>
            )}

            <PostMainActions
              post={post}
              commentsLink={commentsLink}
              getPostClassName={getPostClassName}
              layoutName={layoutName}
            />
          </div>
        </div>
      </div>
    </article>
  );
}

function useSlice({ post, isInPostPage }: OwnProps) {
  const { canSee } = usePermissions();

  return useSelector((state) => {
    const section = getSection(state) || SECTION_HOMEPAGE;
    const postActions = getPostActions({
      post,
      canSee,
      enableShare: true,
      enableSubscribe: getIsMemberAreaInstalled(state),
    });
    const isPostInPreview = getIsPostInPreview(state);
    const showMoreButton = !isPostInPreview && Boolean(postActions.length);
    const postAverageRating = getPostAverageRating(state, resolveId(post));
    const postTotalRatings = getPostTotalRatings(state, resolveId(post));
    const isPostRatingLoading =
      typeof postAverageRating === 'undefined' &&
      typeof postTotalRatings === 'undefined';

    return {
      postLink: isInPostPage ? null : `/${post.slug}`,
      commentsLink: isInPostPage ? undefined : post.link,
      showMoreButton,
      shouldApplyPostDesignInFeed: getShouldApplyPostDesignInFeed(state),
      layoutName: getLayoutName(getLayoutType(state, section)!),
      isDesktop: getIsDesktop(state),
      isCategoryLabelsEnabled: getIsCategoryLabelsEnabled(state),
      isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
      isTagsEnabled: getIsTagsEnabled(state),
      postAverageRating,
      postTotalRatings,
      isPostRatingLoading,
      /**
        We don't have ratings information in SSR and sometimes initial CSR render will have ratings fetched,
        which will result in hydration error. In that case we render ratings in different element for CSR
      */
      renodeRatings: !isSSR(state) && !isPostRatingLoading,
    };
  });
}

export default Post;
