import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { Experiment, Field, FieldPrivacy } from '../../types';
import {
  getNonMultilingualFieldLabel,
  getMultilingualFieldLabel,
} from './utils';

export const useFieldLabel = () => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { multilingual } = useEnvironment();
  const isMultilingualEnabled =
    multilingual.isEnabled &&
    experiments.enabled(Experiment.UseLocalizationService);

  const getFieldLabel = (field: Field) => {
    if (isMultilingualEnabled) {
      return getMultilingualFieldLabel(t, field);
    } else {
      return getNonMultilingualFieldLabel(t, field);
    }
  };

  return {
    getFieldLabel,
  };
};

export const useFieldPrivacyAriaLabel = () => {
  const { t } = useTranslation();
  const { getFieldLabel } = useFieldLabel();

  const getFieldPrivacyAriaLabel = (field: Field) => {
    const fieldLabel = getFieldLabel(field);
    const isPrivate = field.privacy === FieldPrivacy.PRIVATE;

    if (isPrivate) {
      return t('app.widget.fields.privacy-button.aria-label.private', {
        fieldLabel,
      });
    }

    return t('app.widget.fields.privacy-button.aria-label.public', {
      fieldLabel,
    });
  };

  return {
    getFieldPrivacyAriaLabel,
  };
};
