import type { AppData } from '../../viewer.app';
import type { PostCategoriesApi } from '../Post Categories/viewer.controller';
import type { PostCountersApi } from '../Post Counters New/viewer.controller';
import type { PostMetadataApi } from '../Post Metadata/viewer.controller';
import type { PostRatingsApi } from '../Post Ratings/viewer.controller';
import type { PostTagsApi } from '../Post Tags/viewer.controller';
import type { PostTitleApi } from '../Post Title/viewer.controller';
import blocksModel from './model';

export default blocksModel.createController(
  ({ $w, flowAPI, appData: _appData }) => {
    const appData = _appData as AppData;
    return {
      pageReady: async () => {
        const ui = {
          categories: $w('#categories1') as PostCategoriesApi,
          title: $w('#title1') as PostTitleApi,
          ratings: $w('#ratings1') as PostRatingsApi,
          metadata: $w('#metadata1') as PostMetadataApi,
          counters: $w('#postCountersNew1') as PostCountersApi,
          tags: $w('#tags1') as PostTagsApi,
          heroImage: $w('#image') as $w.Image,
        };

        await appData.subjects.postPageRenderModel.subscribe((model) => {
          if (model?.heroImageUrl) {
            ui.heroImage.src = model.heroImageUrl;
          }

          ui.categories.initialize(model);
          ui.title.initialize(model);
          ui.ratings.initialize(model);
          ui.metadata.initialize(model);
          ui.counters.initialize(model);
          ui.tags.initialize(model);
        });
      },

      exports: blocksModel.getExports(),
    };
  },
);
