export enum PackagePickerInteractions {
  ListPageLoaded = 'list_page_loaded',
  CheckoutPageLoaded = 'checkout_page_loaded',
  CheckoutPageInitialized = 'checkout_page_initialized',
  RestrictedPageLoaded = 'restricted_page_loaded',
  StatusPageLoaded = 'status_page_loaded',
  PlanSelected = 'plan_selected',
  OrderGuestCreated = 'create_guest_order',
  OrderCreated = 'create_order',
  CreateSubmission = 'create_submission',
  SetSubmission = 'set_submission',
  CashierWidgetLoaded = 'cashier_widget_loaded',
}
