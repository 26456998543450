import { ControllerParams } from '../Post Metadata/types';
import { CounterController } from './counter-controller';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

const createController = ((controller) => ({
  pageReady: async () => {},
  exports: new CounterController(controller as ControllerParams),
})) satisfies CreateController;

export type PostCountersApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
