import {AppManifestBuilder} from '@wix/yoshi-flow-editor'
import {EVENTS_DETAILS_PAGE_ID} from '@wix/wix-events-commons-statics'
import {WidgetPluginInterfaces} from '@wix/widget-plugins-ooi/interfaces'
import {TFunction} from '../services/translations'
import {SLOT_EVENT_DETAILS_CONTENT, SLOT_EVENT_DETAILS_HEADER} from '../../commons/constants/slots'

export const detailsPageSlotsBuilder =
  ({t}: {t: TFunction}) =>
  async (appManifestBuilder: AppManifestBuilder) => {
    return appManifestBuilder.configureWidget(EVENTS_DETAILS_PAGE_ID, widgetBuilder =>
      widgetBuilder.slots().set({
        [SLOT_EVENT_DETAILS_HEADER]: {
          displayName: t('slotEventDetailsHeader'),
          interfaces: [WidgetPluginInterfaces.EVENT],
        },
        [SLOT_EVENT_DETAILS_CONTENT]: {
          displayName: t('slotEventDetailsContent'),
          interfaces: [WidgetPluginInterfaces.EVENT],
        },
      }),
    )
  }
