import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Spinner, SpinnerTypes, Text } from 'wix-ui-tpa/cssVars';
import { SlotsStatus } from '../../../../../types/types';
import { NoAvailableSlotsViewModel } from '../../../ViewModel/noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import { SlotsPerDay } from '../../../../../utils/slotsPerDay/slotsPerDay';
import { st, classes } from './WeekAvailability.st.css';
import { DataHooks } from './constants';
import NoAvailableSlots from '../../NoAvailableSlots/NoAvailableSlots';
import { DayAvailability } from './DayAvailability/DayAvailability';
import { WidgetLoader } from '../../WidgetLoader/WidgetLoader';

export type DayWithSlotsFactory<SlotsViewModel> = (props: {
  date: string;
  slotsViewModel: SlotsViewModel;
}) => React.ReactNode;

export type DayWithoutSlotsIndicationFactory<SlotsViewModel> = (props: {
  text: string;
  slotsViewModel: SlotsViewModel;
}) => React.ReactNode;

export interface WeekAvailabilityProps<SlotsViewModel> {
  slotsStatus: SlotsStatus;
  slotsPerDays: SlotsPerDay<SlotsViewModel>[];
  noAvailableSlotsViewModel?: NoAvailableSlotsViewModel;
  dayWithSlotsFactory: DayWithSlotsFactory<SlotsViewModel>;
  dayWithoutSlotsIndicationFactory: DayWithoutSlotsIndicationFactory<SlotsViewModel>;
  accessibleDays?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const WeekAvailability = <SlotsViewModel extends object>({
  slotsPerDays,
  slotsStatus,
  noAvailableSlotsViewModel,
  dayWithSlotsFactory,
  dayWithoutSlotsIndicationFactory,
  accessibleDays,
  onKeyDown,
}: WeekAvailabilityProps<SlotsViewModel>) => {
  const { isEditor } = useEnvironment();

  const NoAvailability = () => {
    return noAvailableSlotsViewModel &&
      slotsStatus === SlotsStatus.NO_AVAILABLE_SLOTS ? (
      <NoAvailableSlots viewModel={noAvailableSlotsViewModel!} />
    ) : null;
  };

  const Loader = () => {
    return slotsStatus === SlotsStatus.LOADING ? (
      <WidgetLoader spinnerType={SpinnerTypes.slim} />
    ) : null;
  };

  return (
    <div
      className={classes.root}
      data-hook={DataHooks.WeekAvailability}
      onKeyDown={onKeyDown}
    >
      <div className={classes.week}>
        {slotsPerDays?.map(
          (slotsPerDay: SlotsPerDay<SlotsViewModel>, index) => {
            const { date, slots, isToday, dayWithoutSlots, accessibility } =
              slotsPerDay;
            const isPastDate = !isEditor && slotsPerDay.isPastDate;

            return (
              <div
                className={st(classes.dayWrapper, {
                  isToday: !isEditor && isToday,
                  isPastDate,
                })}
                tabIndex={accessibleDays ? 0 : undefined}
                data-hook={`${DataHooks.DayAvailability}-${date}`}
                data-dayindex={index}
                role="group"
                aria-label={accessibility.dayDetailsAriaLabel}
                key={date}
                id={date}
              >
                <DayDetails slotsPerDay={slotsPerDay} />
                {slotsStatus !== SlotsStatus.LOADING && (
                  <DayAvailability
                    date={date}
                    accessibility={accessibility}
                    isPastDate={isPastDate}
                    slots={dayWithSlotsFactory({
                      date,
                      slotsViewModel: slots,
                    })}
                    noSlots={dayWithoutSlotsIndicationFactory({
                      slotsViewModel: slots,
                      text: dayWithoutSlots,
                    })}
                  />
                )}
              </div>
            );
          },
        )}
      </div>
      <NoAvailability />
      <Loader />
    </div>
  );
};

interface DayDetailsProps<SlotsViewModel> {
  slotsPerDay: SlotsPerDay<SlotsViewModel>;
}

const DayDetails = <SlotsViewModel extends object>({
  slotsPerDay,
}: DayDetailsProps<SlotsViewModel>) => {
  const { weekday, day } = slotsPerDay;

  return (
    <div
      aria-hidden="true"
      className={classes.dayDetails}
      data-hook={DataHooks.DayDetails}
    >
      <Text className={classes.weekday}>{weekday}</Text>
      <Text className={classes.day}>{day}</Text>
    </div>
  );
};

export default WeekAvailability;
