import {EditorSDK} from '@wix/platform-editor-sdk'
import {FlowApi} from '../types'

export type WithApproval = <T>(fn: () => T) => Promise<T>

export const createWithApproval = (
  editorSdk: EditorSDK,
  token: string,
  adi: boolean,
  flowApi: FlowApi,
): WithApproval => {
  if (!adi) {
    return async fn => {
      try {
        return await editorSdk.document.transactions.runAndWaitForApproval(token, fn)
      } catch (error) {
        const isTransactionError = await editorSdk.document.transactions.isConflictError(token, error)

        if (isTransactionError) {
          try {
            return await editorSdk.document.transactions.runAndWaitForApproval(token, fn)
          } catch (secondError) {
            flowApi.reportError(secondError)
          }
        } else {
          flowApi.reportError(error)
        }

        return null
      }
    }
  } else {
    return async fn => fn()
  }
}
