import {
  SchedulingLocationViewModel,
  schedulingLocationViewModelFactory,
} from '../../../service-page-view-model-deprecated/scheduling-location-view-model/schedulingLocationViewModel';
import {
  ServicePageViewModel,
  servicePageViewModelFactory,
} from '../../../service-page-view-model-deprecated/servicePageViewModel';
import {
  SchedulingTimezoneViewModel,
  schedulingTimezoneViewModelFactory,
} from '../../../service-page-view-model-deprecated/shceduling-timezone-view-model/schedulingTimezoneViewModel';

export const initializeViewModels = ({
  config,
  t,
  experiments,
  selectedLocation,
  isSEO,
  isBookingCalendarInstalled,
  settings,
}): {
  viewModel: ServicePageViewModel;
  locationViewModel: SchedulingLocationViewModel;
  timezoneViewModel: SchedulingTimezoneViewModel;
} => {
  const locationViewModel: SchedulingLocationViewModel =
    schedulingLocationViewModelFactory({
      serviceInfoDto: config.serviceDto.info,
      selectedLocation,
      t,
    });
  const timezoneViewModel: SchedulingTimezoneViewModel =
    schedulingTimezoneViewModelFactory({
      businessInfo: config.businessInfo,
      isBookingCalendarInstalled,
    });
  const viewModel: ServicePageViewModel = servicePageViewModelFactory({
    config,
    t,
    experiments,
    viewTimezone: timezoneViewModel.viewTimezone,
    isSEO,
    settings,
  });
  return {
    viewModel,
    locationViewModel,
    timezoneViewModel,
  };
};
