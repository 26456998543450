import PaginationContainer from './components/pagination-container';
import type { PaginationProps } from './types';

export const PAGE_NUMBERS_TO_SHOW = 5;

export const defaultPaginationProps = {
  page: 1,
  pageSize: 10,
  entityCount: 0,
  showPosition: false,
  component: PaginationContainer,
} satisfies Partial<PaginationProps>;

export enum PaginationButtonType {
  FIRST = 'first',
  LAST = 'last',
  NEXT = 'next',
  PREVIOUS = 'previous',
  PAGE = 'page',
}
