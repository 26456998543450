import { CreateActionParams } from '../actions';

export type GetHistory = () => void;

export function createGetHistoryAction({
  context,
  getControllerState,
}: CreateActionParams): GetHistory {
  return async () => {
    const [, setState] = getControllerState();
    const { api } = context;

    const historyData = await api.getHistory();

    setState({ historyData });
  };
}
